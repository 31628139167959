import notification from 'core/libs/helpers/notification';
import {navigate} from 'gatsby';

export const resetPassword = (values) => {
  const formData = ['token', 'uid', 'new_password', 'repeat_password'].reduce(
    (data, field) => {
      data.append(field, values[field]);
      return data;
    },
    new FormData(),
  );

  return {
    types: [
      'RESET_PASSWORD_EMAIL_REQUEST',
      'RESET_PASSWORD_EMAIL_SUCCESS',
      'RESET_PASSWORD_EMAIL_FAILURE',
    ],
    config: {
      url: 'password-reset/confirm/',
      method: 'POST',
      data: formData,
    },
    callback: (success, result) => {
      if (success) {
        notification({
          id: 'password_updated',
          type: 'success',
        });
        navigate('/auth/login');
      } else if (result && result.response && result.response.status >= 400) {
        const notificationMessage = Object.values(
          result.response.data,
        ).toString();
        notification({message: notificationMessage});
      } else {
        notification({message: 'There was an error querying the api'});
      }
    },
  };
};

export default resetPassword;
