import compose from 'core/libs/helpers/compose.js';
import {Field, withFormik} from 'formik';
import FormInputField from 'modules/common/FormInputField';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import * as Yup from 'yup';
import {resetPassword} from '../../actions/reset-password';
import {fixedHeight} from '../styles.scss';

const ResetPasswordForm = (props) => {
  const {
    handleSubmit,
    isResetPassword,
    intl: {formatMessage},
  } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="token"
        type="hidden"
        data-testid="token"
        component={FormInputField}
      />
      <Field
        name="uid"
        type="hidden"
        data-testid="uid"
        component={FormInputField}
      />
      <Field
        name="new_password"
        type="password"
        data-testid="new_password"
        className={`${fixedHeight} input-reset pv3 pr3 pl2 dark-gray ba b--light-gray bw1 br2 w-100 tr mt3 pt3 pb4`}
        component={FormInputField}
        autoComplete="new-password"
        placeholder={formatMessage({id: 'label_new_password'})}
      />
      <Field
        name="repeat_password"
        type="password"
        data-testid="repeat_password"
        className={`${fixedHeight} input-reset pv3 pr3 pl2 dark-gray ba b--light-gray bw1 br2 w-100 tr mt3 pt3 pb4`}
        component={FormInputField}
        autoComplete="new-password"
        placeholder={formatMessage({id: 'repeat_password'})}
      />
      <div className="mt4">
        <button
          aria-label="Reset password"
          type="submit"
          data-testid="reset_password"
          className="button-reset bn f7 f5-ns fw6 white pv3 ph4 br2 bg-green-light bg-animate hover-bg-green pointer"
        >
          <FormattedMessage
            id={isResetPassword ? 'reset_password' : 'create_new_password'}
          />
        </button>
      </div>
    </form>
  );
};

ResetPasswordForm.propTypes = {
  isResetPassword: PropTypes.bool,
  handleSubmit: PropTypes.func,
  intl: PropTypes.object,
};

const enhance = compose(
  connect(),
  injectIntl,
  withFormik({
    mapPropsToValues: ({initialValues}) => {
      return {
        token: initialValues.token,
        uid: initialValues.uid,
        new_password: '',
        repeat_password: '',
      };
    },
    handleSubmit: (values, {props}) => {
      props.dispatch(resetPassword(values));
    },
    validationSchema: ({intl: {formatMessage}}) =>
      Yup.object().shape({
        new_password: Yup.string()
          .min(8, formatMessage({id: 'password_number_chars'}))
          .required(formatMessage({id: 'field_required'})),
        repeat_password: Yup.string()
          .oneOf(
            [Yup.ref('new_password'), null],
            formatMessage({id: 'password_not_match'}),
          )
          .required(formatMessage({id: 'field_required'})),
      }),
  }),
);

export default enhance(ResetPasswordForm);
