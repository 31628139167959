const parseURLSearch = (search) => {
  const params = {};
  search
    .split(/[?&]/)
    .filter((param) => param !== '')
    .forEach((param) => {
      const parts = param.split('=');
      // eslint-disable-next-line prefer-destructuring
      params[parts[0]] = parts[1];
    });
  return params;
};

export default parseURLSearch;
