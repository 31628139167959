import parseURLSearch from 'core/libs/helpers/parseURLSearch';
import Container from 'modules/common/Container';
import Head from 'modules/common/Head';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {withProps} from 'recompose';
import ResetPasswordForm from '../components/ResetPasswordForm';

const {PARTNER} = process.env;

const ResetPassword = (props) => {
  const intl = useIntl();
  const {formatMessage} = intl;
  const {params, isResetPassword} = props;
  return (
    <Container className="mt6-ns mb6-ns mw7-ns bg-white pa4 ph5-ns">
      <Head
        title={formatMessage({
          // eslint-disable-next-line no-nested-ternary
          id: isResetPassword
            ? PARTNER === 'BARMEJ'
              ? 'helmet_reset_password'
              : 'partner_helmet_reset_password'
            : PARTNER === 'BARMEJ'
            ? 'helmet_create_new_password'
            : 'partner_helmet_create_new_password',
        })}
      />
      <h3 className="f3 fw6 mb3 tr">
        <FormattedMessage
          id={isResetPassword ? 'reset_password' : 'create_new_password'}
        />
      </h3>
      <ResetPasswordForm
        initialValues={params}
        isResetPassword={isResetPassword}
      />
    </Container>
  );
};

ResetPassword.propTypes = {
  params: PropTypes.object.isRequired,
  isResetPassword: PropTypes.bool,
};

const enhance = withProps(({location: {search}}) => ({
  params: parseURLSearch(search),
}));

export default enhance(ResetPassword);
